// Copyright © 2021 Move Closer
import { MappingTypes } from '@movecloser/front-core';
import { OpenStrategy, Target } from './navigation.contracts';
const mapLinks = (links) => {
    if (!links || links.length === 0) {
        return [];
    }
    return links.map((linkItem) => {
        var _a, _b;
        return ({
            isExternal: ((_a = linkItem.target) === null || _a === void 0 ? void 0 : _a.type) === Target.External,
            label: linkItem.label,
            icon: linkItem.icon,
            target: (_b = linkItem.target) === null || _b === void 0 ? void 0 : _b.link,
            newTab: linkItem.strategy === OpenStrategy.Blank,
            children: mapLinks(linkItem.children)
        });
    });
};
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const navigationAdapterMap = {
    name: 'name',
    slot: 'slot',
    links: {
        type: MappingTypes.Function,
        value: item => mapLinks(item.links)
    }
};
