// Copyright © 2021 Move Closer
import { __decorate, __param } from "tslib";
import { ApiConnectorFactory, Inject, Injectable, mapModel, Repository, ResourceActionFailed } from '@movecloser/front-core';
import { ContentType } from '@d24/modules/front';
import { buildMetaTags } from '@support/meta';
import { SiteServiceType } from '@service/site';
import { PaywallMode } from '@service/paywall';
import { resolveFromStatus } from '@exception/connector-errors';
import { contentAdapterMap } from '../models/content.adapter';
import { Content } from '../models/content';
import { trimSlugForRegistry } from '../helpers/content';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let ContentRepository = class ContentRepository extends Repository {
    constructor(sites, connectorFactory) {
        super(connectorFactory);
        this.sites = sites;
    }
    async load(slug, query = {}, translateCode = true) {
        var _a, _b, _c, _d, _e, _f, _g;
        const response = await this.connector.call('content', 'get', { slug: trimSlugForRegistry(slug) }, { ...query });
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, translateCode ? resolveFromStatus(response.status) : response.status, {
                ...(_b = response.errors) === null || _b === void 0 ? void 0 : _b.errors,
                ...(_c = response.errors) === null || _c === void 0 ? void 0 : _c.data,
                slug: slug
            });
        }
        const related = response.data.meta.dicts || {};
        const pagination = response.data.meta.pagination || {};
        let additionalMeta = {};
        if ([ContentType.Article, ContentType.LiveArticle].includes(response.data.data.type)) {
            additionalMeta = { ...additionalMeta, ...{ publicationDate: new Date(response.data.data.publicationDate).toISOString(), ogType: 'article' } };
        }
        else {
            const siteImage = this.sites.getSiteImage();
            additionalMeta = { ...additionalMeta, ...(siteImage ? { ogImage: siteImage, twitterImage: siteImage } : {}) };
        }
        const meta = ((_e = (_d = response.data.data) === null || _d === void 0 ? void 0 : _d.properties) === null || _e === void 0 ? void 0 : _e.meta) ? await buildMetaTags({ ...(_g = (_f = response.data.data) === null || _f === void 0 ? void 0 : _f.properties) === null || _g === void 0 ? void 0 : _g.meta, ...additionalMeta }, related)
            : [{
                    name: 'robots',
                    content: 'index, follow, max-image-preview:large'
                }];
        return {
            canonical: this.resolveCanonical(response.data.data),
            pagination: this.resolvePagination(pagination),
            content: mapModel(response.data.data, contentAdapterMap),
            meta,
            paywall: response.data.meta.paywall || PaywallMode.AccessGranted,
            related
        };
    }
    async loadUserArticles() {
        var _a;
        const response = await this.connector.call('me', 'articles', {}, {});
        if (!response.isSuccessful()) {
            throw new ResourceActionFailed((_a = response.errors) === null || _a === void 0 ? void 0 : _a.message, resolveFromStatus(response), {});
        }
        return this.composeCollection(response.data.articles, Content, response.data.data);
    }
    resolveCanonical(data) {
        return typeof data.properties.meta === 'object' && typeof data.properties.meta.canonical === 'string'
            ? data.properties.meta.canonical : '';
    }
    resolvePagination(pagination) {
        return typeof pagination === 'object' && typeof pagination === 'object'
            ? pagination : {};
    }
};
ContentRepository = __decorate([
    Injectable(),
    __param(0, Inject(SiteServiceType)), __param(1, Inject(ApiConnectorFactory))
], ContentRepository);
export { ContentRepository };
